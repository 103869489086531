import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { makeParams } from 'app/modules-core/utility/helpers';
import * as _ from 'lodash';
import { RESOURCE_URL_BASE } from 'app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  urlBase = 'attachments';

  httpOptions = {
    params: new HttpParams()
  }

  constructor(
    private http: HttpClient,
  ) {
  }

  getAll(opts = {}) {
    return this.http.get(this.urlBase, {
      params: makeParams(_.merge({
        httpParams: this.httpOptions.params
      }, { queryParams: opts }))
    }).pipe(map((resp: any) => {
      resp.data = _.map(resp.data, v => {
        v.fullpath = `${RESOURCE_URL_BASE}${v.filePath}`;
        // v.fullpath = `${RESOURCE_URL_BASE}${v.path}${v.fileName}`;
        v.mediumFullpath = `${RESOURCE_URL_BASE}${v.path}${v.fileName}`;
        v.thumbFullpath = `${RESOURCE_URL_BASE}${v.path}${v.fileName}`;
        return v;
      });
      return resp.data;
    }));
  }
  getAllbudgetApprovalAttchs(opts = {}) {
    return this.http.get(`${this.urlBase}/entity-ids`, {
      params: makeParams(_.merge({
        httpParams: this.httpOptions.params
      }, { queryParams: opts }))
    }).pipe(map((resp: any) => {
      resp.data = _.map(resp.data, v => {
        v.fullpath = `${RESOURCE_URL_BASE}${v.filePath}`;
        // v.fullpath = `${RESOURCE_URL_BASE}${v.path}${v.fileName}`;
        v.mediumFullpath = `${RESOURCE_URL_BASE}${v.path}${v.fileName}`;
        v.thumbFullpath = `${RESOURCE_URL_BASE}${v.path}${v.fileName}`;
        return v;
      });
      return resp.data;
    }));
  }

  delete(id) {
    return this.http.delete(`${this.urlBase}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

} //class


