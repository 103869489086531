import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import * as _ from "lodash";
import { makeParams } from 'app/modules-core/utility/helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserManualService = /** @class */ (function () {
    function UserManualService(http) {
        this.http = http;
        this.urlBase = 'user-manuals';
        this.httpOptions = {
            params: new HttpParams()
        };
    }
    UserManualService.prototype.get = function () {
        return this.http.get("" + this.urlBase)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserManualService.prototype.getAll = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get("" + this.urlBase, {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params,
            }, { queryParams: opts })),
        })
            .pipe(map(function (resp) {
            return resp.data;
        }));
    };
    UserManualService.prototype.getById = function (id) {
        return this.http.get(this.urlBase + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserManualService.prototype.delete = function (id) {
        return this.http.delete(this.urlBase + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    UserManualService.prototype.create = function (data) {
        return this.http.post("" + this.urlBase, data).pipe(map(function (resp) { return resp.data; }));
    };
    UserManualService.prototype.update = function (id, data) {
        return this.http.put(this.urlBase + "/" + id, data).pipe(map(function (resp) { return resp.data; }));
    };
    UserManualService.ngInjectableDef = i0.defineInjectable({ factory: function UserManualService_Factory() { return new UserManualService(i0.inject(i1.HttpClient)); }, token: UserManualService, providedIn: "root" });
    return UserManualService;
}());
export { UserManualService };
