import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { makeParams } from 'app/modules-core/utility/helpers';
import * as _ from 'lodash';
import { RESOURCE_URL_BASE } from 'app/app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AttachmentService = /** @class */ (function () {
    function AttachmentService(http) {
        this.http = http;
        this.urlBase = 'attachments';
        this.httpOptions = {
            params: new HttpParams()
        };
    }
    AttachmentService.prototype.getAll = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http.get(this.urlBase, {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params
            }, { queryParams: opts }))
        }).pipe(map(function (resp) {
            resp.data = _.map(resp.data, function (v) {
                v.fullpath = "" + RESOURCE_URL_BASE + v.filePath;
                // v.fullpath = `${RESOURCE_URL_BASE}${v.path}${v.fileName}`;
                v.mediumFullpath = "" + RESOURCE_URL_BASE + v.path + v.fileName;
                v.thumbFullpath = "" + RESOURCE_URL_BASE + v.path + v.fileName;
                return v;
            });
            return resp.data;
        }));
    };
    AttachmentService.prototype.getAllbudgetApprovalAttchs = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http.get(this.urlBase + "/entity-ids", {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params
            }, { queryParams: opts }))
        }).pipe(map(function (resp) {
            resp.data = _.map(resp.data, function (v) {
                v.fullpath = "" + RESOURCE_URL_BASE + v.filePath;
                // v.fullpath = `${RESOURCE_URL_BASE}${v.path}${v.fileName}`;
                v.mediumFullpath = "" + RESOURCE_URL_BASE + v.path + v.fileName;
                v.thumbFullpath = "" + RESOURCE_URL_BASE + v.path + v.fileName;
                return v;
            });
            return resp.data;
        }));
    };
    AttachmentService.prototype.delete = function (id) {
        return this.http.delete(this.urlBase + "/" + id)
            .pipe(map(function (resp) { return resp.data; }));
    };
    AttachmentService.ngInjectableDef = i0.defineInjectable({ factory: function AttachmentService_Factory() { return new AttachmentService(i0.inject(i1.HttpClient)); }, token: AttachmentService, providedIn: "root" });
    return AttachmentService;
}()); //class
export { AttachmentService };
